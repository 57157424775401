import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { ReactComponent as ConfirmationIcon } from "../../assets/signup/confirmation.svg";
import { colors } from "../../utils/colors";
import { windowHeight, windowWidth } from "../../utils/utils";

const SignupConfirmationImistScreen = () => {
  const navigate = useNavigate();

  const handleConfirmation = () => {
    navigate("/");
  };

  return (
    <Grid
      container
      sx={{
        height: "90vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.bg_color,
      }}
    >
      <Paper
        elevation={4}
        sx={{
          borderRadius: "20px",
          width: "60%",
          padding: "40px",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh", // Adjust height to better center content
        }}
      >
        {/* Confirmation Icon */}
        <ConfirmationIcon
          style={{
            width: windowHeight / 2.5,
            height: windowHeight / 2.5,
            marginRight: windowWidth / 40,
            marginBottom: "20px",
          }}
        />

        {/* Welcome Message */}
        <Typography variant="h4" sx={{ mb: 2 }}>
          Bienvenue chez Juridia !
        </Typography>

        {/* Description Text */}
        <Typography variant="body1" sx={{ mb: 4 }}>
          Nous vous avons envoyé un email de confirmation. Veuillez consulter
          votre boîte de réception et suivre les instructions pour finaliser
          votre inscription.
        </Typography>

        {/* Confirmation Button */}
        <CustomButton
          text="C’est compris !"
          outlined={false}
          onClick={handleConfirmation}
          sx={{ width: "50%", padding: "10px 0" }}
        />
      </Paper>
    </Grid>
  );
};

export default SignupConfirmationImistScreen;
