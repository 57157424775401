import React, { useEffect, useRef, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import SendIcon from "../../../assets/chat/send.png";
import DisabledSendIcon from "../../../assets/chat/disabledSend.png";
import StopIcon from "../../../assets/chat/stop.png";
import {
  List,
  IconButton,
  Typography,
  TextField,
  Paper,
  Box,
  Grid,
  InputAdornment,
  Divider,
  Button,
  Menu,
  MenuItem,
  ToggleButton,
  CircularProgress,
  styled,
  Tooltip,
  ToggleButtonGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UploadFile } from "@mui/icons-material";
import ReactGA from "react-ga4";
import SearchIcon from "@mui/icons-material/Search";
import { EventSourcePolyfill } from "event-source-polyfill";
import { v4 as uuidv4 } from "uuid";
// import SendIcon from "@mui/icons-material/Send";
import useMessages from "../../../hooks/useMessage";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import GetAppIcon from "@mui/icons-material/GetApp";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import { checkAndMoveToStep, windowWidth } from "../../../utils/utils";
import { api_subscription_key, backendUrl } from "../../../config";
import { MESSAGE_STATUS } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteConversations,
  fetchConversation,
  setMessages_rd,
  systemSendMessage,
} from "../../../redux/actions/conversationAction";
// import { RenderConversations } from "./RenderConversations";
import { store } from "../../../redux/store";
import { getCitations, windowHeight } from "../../../utils/utils";
import { isMobile } from "../../../utils/utils";
import RenderConversations from "./RenderConversations";
import { backendClient } from "../../../api/backend";
import useDocumentStore from "./useDocumentStore";
import useConversationsStore from "./useConversationsStore";
import { colors } from "../../../utils/colors";
import { fontSize } from "../../../utils/fonts";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useJoyride } from "../../../contexts/JoyrideContext";
import { setIsLoadingOnboarding } from "../../../redux/actions/userAction";

// Custom Styled ToggleButton
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  flex: 1,
  backgroundColor: "#f0f0f0",
  color: "rgba(0, 0, 0, 0.87)",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "black",
  },
  padding: "6px 12px",
  "& .MuiToggleButton-label": {
    paddingTop: "6px",
  },
  "&:not(:first-of-type)": {
    borderRadius: theme.shape.borderRadius,
  },
  "&:first-of-type": {
    borderRadius: theme.shape.borderRadius,
  },
  border: "none",
  "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRight: "none",
  },
  "&.MuiToggleButtonGroup-grouped": {
    border: "none",
    "&:not(:first-of-type)": {
      marginLeft: 0,
    },
  },
  "&:hover": {
    backgroundColor: "#d6d6d6", // Slightly darker grey on hover for unselected buttons
  },
}));

const Chat = (props) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const { conversationId } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user?.user);
  const user_email = useSelector((state) => state.auth.user?.user?.email);
  const user_id = useSelector((state) => state.auth.user?.user?.id);

  // const [selectedDocuments, setSelectedDocuments] = useState([]);
  const textFocusRef = useRef();
  const [userMessage, setUserMessage] = useState("");
  const [isMessagePending, setIsMessagePending] = useState(false);
  // const { userSendMessage, systemSendMessage, messages, setMessages } =
  //   useMessages(conversationId || "");
  const { userSendMessage, systemSendMessage, messages, setMessages } =
    useConversationsStore();

  const [filteredMessages, setfilteredMessages] = useState(null);
  const [searchText, setSearchText] = useState(null);
  // Add a loading state for export
  const [isExporting, setIsExporting] = useState(false);
  // Add the following state for mode selection
  const [mode, setMode] = useState("loi");

  // const parentScreen = location.state?.parent_screen;
  const [textInputRows, setTextInputRows] = useState(1);

  const [displayCitations, setDisplayCitations] = useState(false);
  const [isFetchingConversation, setIsFetchingConversation] = useState(false);
  const [hasCheckedMessages, setHasCheckedMessages] = useState(false);

  const { setSelectedCitation, loading, setLoading, selectedCitation } =
    useDocumentStore();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef();
  const [uploadController, setUploadController] = useState(null);

  // Export related states
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const isFirstFetch = useRef(true);
  const hasFetchedMessages = useRef(false);
  const location = useLocation();
  const prevLocationRef = useRef(location.pathname);
  const { enqueueSnackbar } = useSnackbar();
  const eventSourceRef = useRef(null);

  const handleTextChange = (event) => {
    const sanitizedMessage = event.target.value.replace(/<br\s*\/?>/gi, "\n");
    setUserMessage(sanitizedMessage);
  };

  const handleTextSearchChange = (event) => {
    const searchText = event.target.value;
    setSearchText(searchText);

    // Filter messages based on content or sub_question answer
    const filteredMessages_ = messages.filter((message) => {
      // Check if the main content includes the search text
      const contentMatch =
        message.content &&
        message.content.toLowerCase().includes(searchText.toLowerCase());

      // Check if any sub-processes contain a matching sub-question answer
      const subQuestionMatch = message.sub_processes?.some(
        (subProcess) =>
          subProcess.metadata_map?.sub_question?.answer &&
          subProcess.metadata_map.sub_question?.answer
            ?.toLowerCase()
            .includes(searchText.toLowerCase())
      );

      // Google Analytics event for search
      ReactGA.event({
        category: "Search",
        action: "User searched in conversation",
        label: searchText,
      });

      // Return true if either content or sub-question answer matches
      return contentMatch || subQuestionMatch;
    });

    setfilteredMessages(filteredMessages_);
  };

  const setSuggestedMessage = (text) => {
    setUserMessage(text);
    if (textFocusRef.current) {
      textFocusRef.current.focus();
    }
  };

  useEffect(() => {
    setSelectedCitation([]);
    setMessages([]);
    setSelectedFiles([]);
    setIsUploadingDocuments(false);
  }, [location.pathname]);

  // Fetch conversation data when the component mounts
  useEffect(() => {
    const fetchConversationData = async () => {
      if (isFirstFetch.current && conversationId) {
        isFirstFetch.current = false;
        setIsFetchingConversation(true);

        const conversationData = await dispatch(
          fetchConversation(conversationId, user_id, true)
        );

        hasFetchedMessages.current = true; // Mark that messages were fetched
        if (conversationData?.messages?.length) {
          setMessages(conversationData.messages);
        }
        setIsFetchingConversation(false);
      }
    };

    fetchConversationData();
  }, [dispatch, conversationId, user_id, setMessages]);

  useEffect(() => {
    const anyChecked = messages.some((message) => message.checked);
    setHasCheckedMessages(anyChecked);
  }, [messages]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/chat", title: "Chat Page" });
  }, []);

  useEffect(() => {
    let currentPathname = location.pathname;

    const cleanup = () => {
      if (eventSourceRef.current) {
        console.log("Closing EventSource connection...");
        eventSourceRef.current.close();
        eventSourceRef.current = null;
        setIsMessagePending(false);
      }
    };

    // Clean up on route change
    const handleRouteChange = () => {
      if (location.pathname !== currentPathname) {
        cleanup();
        currentPathname = location.pathname;
      }
    };

    // Watch for route changes
    return () => {
      cleanup();
      handleRouteChange();
    };
  }, [location.pathname]);

  const [isUploadingDocuments, setIsUploadingDocuments] = useState(false);
  // const { startTour, isStepActive, setCurrentStepIndex } = useJoyride();

  const submit = useCallback(async () => {
    if (!userMessage && !selectedFiles) {
      console.error("No user message or file provided.");
      return;
    }

    //
    dispatch(setIsLoadingOnboarding(false));

    if (!conversationId) {
      console.error("No conversation ID provided.");
      return;
    }

    // Close any existing EventSource connection
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
      setIsMessagePending(false);
    }

    setIsMessagePending(true);
    const cleanedMessage = userMessage.replace(/<br\s*\/?>/gi, "\n").trim();
    console.log("selectedFiles", selectedFiles);

    if (!selectedFiles || (selectedFiles && selectedFiles.length === 0)) {
      userSendMessage(cleanedMessage, selectedFiles);
      setUserMessage("");
      setSelectedFiles([]);
    }

    const tokenPayload = backendClient.getToken();
    if (!tokenPayload) {
      console.error("No authentication token available.");
      setIsMessagePending(false);
      setUserMessage("");
      return;
    }

    const token = `${tokenPayload.token_type
      .charAt(0)
      .toUpperCase()}${tokenPayload.token_type.slice(1)} ${encodeURIComponent(
      tokenPayload.access_token
    )}`;

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let fileIds = [];

    // Handle file upload if a file is selected
    if (selectedFiles && selectedFiles.length > 0) {
      setIsUploadingDocuments(true);

      const controller = new AbortController();
      setUploadController(controller);

      const uploadFormData = new FormData();
      selectedFiles.forEach((file) => {
        uploadFormData.append("documents", file);
      });
      uploadFormData.append("organization_name", user?.organization_name || "");

      try {
        const uploadResponse = await fetch(
          `${backendUrl}api/conversation/${conversationId}/upload`,
          {
            method: "POST",
            headers: {
              Authorization: token,
            },
            body: uploadFormData,
            signal: controller.signal, // Add abort signal to the fetch request
          }
        );

        if (!uploadResponse.ok) {
          throw new Error(
            `File upload failed with status: ${uploadResponse.status}`
          );
        }

        const uploadResult = await uploadResponse.json();
        const uploadedFilesInfo = uploadResult.map((file) => ({
          fileName: file.file_name,
          originalFileName: file.original_file_name,
          fileUrl: file.url,
          fileLanguage: file.language,
          docType: "pdf",
        }));
        fileIds = fileIds.concat(uploadResult.map((file) => file.file_id));

        userSendMessage(cleanedMessage, uploadedFilesInfo);
        setUserMessage("");
        setSelectedFiles([]);
        console.log("Files uploaded successfully:", uploadResult);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("File upload was cancelled");
          return;
        }
        console.error("Failed to upload files:", error);
        setIsMessagePending(false);
        return;
      } finally {
        // setUploadController(null);
        setIsUploadingDocuments(false);
      }
    }

    console.log("fileId", fileIds);

    // Build the message endpoint with file_id if present
    const encodedUserMessage = encodeURIComponent(userMessage);
    const encodedOrganizationName = encodeURIComponent(user?.organization_name);
    const fileIdsQuery =
      fileIds && fileIds.length > 0
        ? `&file_ids=${encodeURIComponent(fileIds.join(","))}`
        : "";

    const messageEndpoint = `${backendUrl}api/conversation/${conversationId}/message?user_id=${
      user.id
    }&mode=${encodeURIComponent(
      mode
    )}&organization_name=${encodedOrganizationName}&user_message=${encodedUserMessage}&timezone=${encodeURIComponent(
      timezone
    )}${fileIdsQuery}`;

    const events = new EventSourcePolyfill(messageEndpoint, {
      headers: {
        Authorization: token,
        Connection: "keep-alive",
      },
      heartbeatTimeout: 1200000,
    });

    console.log("---events", events);
    eventSourceRef.current = events;

    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data);
      systemSendMessage(parsedData);
      setfilteredMessages(parsedData);

      if (
        parsedData.status === MESSAGE_STATUS.SUCCESS ||
        parsedData.status === MESSAGE_STATUS.ERROR
      ) {
        events.close();
        setIsMessagePending(false);
        setDisplayCitations(true);
        dispatch(setIsLoadingOnboarding(false));
      }
    };

    events.onerror = (event) => {
      events.close();
      eventSourceRef.current = null;
      setIsMessagePending(false);

      if (event.status === 403) {
        console.error("Access forbidden (403).");
        setIsMessagePending(false);
      }
    };
  }, [
    conversationId,
    userMessage,
    selectedFiles,
    userSendMessage,
    systemSendMessage,
    setfilteredMessages,
    mode,
  ]);

  // Add cleanup in useEffect
  useEffect(() => {
    return () => {
      if (uploadController) {
        uploadController.abort();
        setUploadController(null);
      }
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        setIsMessagePending(false);
        setIsUploadingDocuments(false);
        setSelectedFiles([]);
      }
    };
  }, [uploadController]);

  // useEffect(() => {
  //   return () => {
  //     console.log("CLOSING");
  //     if (eventSourceRef.current) {
  //       console.log("closing conversaiton...");
  //       eventSourceRef.current.close(); // Close connection on unmount
  //       setIsMessagePending(false);
  //       setIsUploadingDocuments(false);
  //       setSelectedFiles([]);
  //     }
  //   };
  // }, []);

  // Export functionality
  const handleExport = (format: "pdf" | "docx" | "email") => {
    // Set loading state to true
    setIsExporting(true);

    const checkedMessages = messages.reduce((result, message, index) => {
      if (message.checked) {
        // Check if the previous message exists and its role is "user"
        if (index > 0 && messages[index - 1].role === "user") {
          // Add the previous user message with title
          result.push({
            id: messages[index - 1].id,
            content: `<strong>Utilisateur:</strong> ${
              messages[index - 1].content
            }`, // Add "User" title
          });
        }
        // Add the current checked message with title
        result.push({
          id: message.id,
          content: `<strong>Réponse Juridia:</strong> ${message.content}`, // Add "Juridia" title
        });

        // Fetch and format citations from the current message
        const citations = getCitations(message);

        console.log("citations", citations);

        if (citations.length > 0) {
          const formattedCitations = citations.map((citation) => {
            return `<br/><strong>Référence :</strong><br/>
            <strong>Titre:</strong> ${citation.long_title}<br/>
            <strong>Section:</strong> ${citation.section_title}<br/>
            <strong>Text:</strong> ${citation.text}<br/><br/>`;
          });

          // Append formatted citations to the result
          result.push({
            id: `${uuidv4()}`,
            content: formattedCitations.join(""), // Join citations as one string
          });

          // Google Analytics event for export
          ReactGA.event({
            category: "Export",
            action: "User exported messages",
            label: format,
          });
        }
      }
      return result;
    }, []);

    console.log("checkedMessages", checkedMessages);
    backendClient
      .exportMessages(checkedMessages, format, user_email)
      .then(async (blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        // Adjust the file name based on the export format
        if (format === "email") {
          a.download = "export.eml"; // Use .eml extension for email
        } else {
          a.download = `export.${format}`; // Keep the original logic for pdf, docx, etc.
        }
        a.click();
        enqueueSnackbar(`Messages exportés en format ${format} !`, {
          variant: "success",
        });
      })
      .catch((err) => console.error(err))
      .finally(() => {
        // Set loading state to false
        setIsExporting(false);
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to handle mode change
  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
    }

    // if (isStepActive(1)) {
    //   checkAndMoveToStep(
    //     '[value="loi"]',
    //     setCurrentStepIndex, // Function to set Joyride step
    //     2 // Index of the next step
    //   );
    // }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    const MAX_FILES = 5; // Maximum number of files
    const MAX_FILE_SIZE_MB = 5; // Maximum file size in MB

    // Validate the number of files
    if (files.length > MAX_FILES) {
      enqueueSnackbar(t("max_files_limit", { count: MAX_FILES }), {
        variant: "error",
      });
      return;
    }

    // Validate each file's size
    const invalidFiles = files.filter(
      (file) => file.size > MAX_FILE_SIZE_MB * 1024 * 1024
    );

    if (invalidFiles.length > 0) {
      enqueueSnackbar(
        t("file_size_limit_exceeded", { size: MAX_FILE_SIZE_MB }),
        { variant: "error" }
      );
      return;
    }

    setSelectedFiles(files); // Set valid files
    enqueueSnackbar(t("files_selected", { count: files.length }), {
      variant: "info",
    });
  };

  const clearFileInput = () => {
    setSelectedFiles([]); // Reset selected file
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear file input field
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        sx={{ mb: 2 }}
        dir={currentLang === "ar" ? "rtl" : "ltr"}
      >
        <BackButton navigate_to={"/conversation"} />

        {!isMobile() && (
          <Box sx={{ flexGrow: 1, ml: 1 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t("search_placeholder")}
              onChange={handleTextSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton disabled sx={{ margin: 0, padding: 0 }}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                style: { fontSize: "14px" }, // Adjust font size here
              }}
            />
          </Box>
        )}
        <Box
          display="flex"
          justifyContent={currentLang === "ar" ? "flex-start" : "flex-end"} // Adjust alignment
          sx={{
            marginLeft: currentLang === "ar" ? 0 : 2,
            marginRight: currentLang === "ar" ? 2 : 0,
          }} // Adjust margin for RTL
        >
          <ToggleButtonGroup
            value={mode}
            exclusive
            onChange={handleModeChange}
            aria-label="Mode selection"
          >
            <Tooltip
              title="Mode Réglementation: Recherches limitées aux textes réglementaires."
              arrow
            >
              <StyledToggleButton
                sx={{ fontSize: fontSize.xsmall, mx: 1 }}
                value="loi"
              >
                {t("mode_reg")}
              </StyledToggleButton>
            </Tooltip>
            <Tooltip
              title="Mode Jurisprudence: Recherches limitées aux jurisprudences."
              arrow
            >
              <StyledToggleButton
                sx={{
                  fontSize: fontSize.xsmall,
                  mx: currentLang === "ar" ? 0 : 1,
                }}
                value="jp"
              >
                {t("mode_jp")}
              </StyledToggleButton>
            </Tooltip>
            <Tooltip
              title="Mode Mixte: Recherches combinant textes réglmentaires et jurisprudences."
              arrow
            >
              <StyledToggleButton
                sx={{
                  fontSize: fontSize.xsmall,
                  mr: currentLang === "ar" ? 1 : 1,
                }}
                value="loi_et_jp"
              >
                {t("mode_both")}
              </StyledToggleButton>
            </Tooltip>
            {["saad.lachhab@juridia.ma", "omar.eladlouni@juridia.ma"].includes(
              user_email
            ) && (
              <Tooltip title="Base de données ONSSA." arrow>
                <StyledToggleButton
                  sx={{
                    fontSize: fontSize.xsmall,
                    mr: currentLang === "ar" ? 1 : 0,
                  }}
                  value="custom"
                >
                  {"ONSSA"}
                </StyledToggleButton>
              </Tooltip>
            )}
          </ToggleButtonGroup>
        </Box>

        <Tooltip title="Exporter" placement="bottom">
          <span>
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              color="primary"
              sx={{
                marginLeft: currentLang === "ar" ? 0 : 2,
                marginRight: currentLang === "ar" ? 2 : 0,
              }}
              disabled={!hasCheckedMessages}
            >
              <GetAppIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ "aria-labelledby": "basic-button" }}
          dir={currentLang === "ar" ? "rtl" : "ltr"}
        >
          {isExporting ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={2}
            >
              <CircularProgress size={24} />
            </Box>
          ) : (
            [
              <MenuItem key="pdf" onClick={() => handleExport("pdf")}>
                <PictureAsPdfIcon className="mr-2" /> {t("export_pdf")}
              </MenuItem>,
              <MenuItem key="docx" onClick={() => handleExport("docx")}>
                <DescriptionIcon className="mr-2" /> {t("export_docx")}
              </MenuItem>,
              <MenuItem key="email" onClick={() => handleExport("email")}>
                <EmailIcon className="mr-2" /> {t("export_email")}
              </MenuItem>,
            ]
          )}
        </Menu>
      </Box>

      <Divider />

      {/* Messages List */}
      <List sx={{ overflowY: "auto", flexGrow: 1, padding: 0 }}>
        {/* <Typography variant="subtitle1" sx={{ p: 2 }}>
          Début de la conversation
        </Typography> */}
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{
            textAlign: currentLang === "ar" ? "right" : "center", // Adjust text alignment
            p: 2,
            fontWeight: 16,
            fontSize: fontSize.medium,
            color: "text.secondary", // MUI default text color
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "::before": {
              content: '""',
              flex: 1,
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`, // MUI default divider color
              marginRight: currentLang === "ar" ? "10px" : "0",
              marginLeft: currentLang === "ar" ? "0" : "10px", // Swap for RTL
            },
            "::after": {
              content: '""',
              flex: 1,
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`, // MUI default divider color
              marginLeft: currentLang === "ar" ? "10px" : "0",
              marginRight: currentLang === "ar" ? "0" : "10px", // Swap for RTL
            },
          }}
        >
          {t("conversation_start")}
        </Typography>

        {filteredMessages?.length > 0 ? (
          <RenderConversations
            messages={filteredMessages}
            setUserMessage={setSuggestedMessage}
            isFetchingConversation={isFetchingConversation}
            documents={[]}
          />
        ) : searchText?.length > 0 ? (
          <Typography variant="subtitle1" sx={{ p: 2 }}>
            {t("no_messages")}
          </Typography>
        ) : (
          <RenderConversations
            messages={messages}
            setUserMessage={setSuggestedMessage}
            isFetchingConversation={isFetchingConversation}
            documents={[]}
          />
        )}
      </List>

      {/* <Divider /> */}

      {/* Message Input Section */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          // alignItems: "center",
          flexDirection: "column",
          justifyContent: "flex-end",
          overflow: "hidden",
          height: selectedFiles?.length > 0 ? "100px" : "80px",
          // alignItems: "stretch",
          p: 0,
        }}
      >
        {(isUploadingDocuments || selectedFiles?.length > 0) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // backgroundColor: "#f9f9f9",
              padding: "10px",
              borderRadius: "4px",
              marginBottom: "8px",
              height: "50px", // Fixed height for consistency
              overflowX: "auto", // Allow scrolling if multiple files
              whiteSpace: "nowrap", // Prevent wrapping of file names
            }}
          >
            {isUploadingDocuments ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                  width: "100%", // Center content
                }}
              >
                <CircularProgress size={24} />
                <Typography sx={{ marginLeft: "8px" }}>
                  Téléchargement des documents...
                </Typography>
              </Box>
            ) : (
              selectedFiles?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    padding: "10px",
                    backgroundColor: "#f9f9f9",
                    borderRadius: "8px",
                    marginBottom: "10px",
                  }}
                >
                  {selectedFiles?.map((file, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#f1f1f1",
                        padding: "8px",
                        borderRadius: "4px",
                        marginRight: "8px",
                        marginBottom: "8px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <DescriptionIcon
                        sx={{ marginRight: "8px", color: "#616161" }}
                      />
                      <Tooltip title={file.name} arrow placement="top">
                        <p
                          style={{ maxWidth: "100px" }}
                          className="font-medium text-gray-800 truncate"
                        >
                          {file.name}
                        </p>
                      </Tooltip>
                      <IconButton
                        onClick={() => {
                          const updatedFiles = selectedFiles?.filter(
                            (_, i) => i !== index
                          );
                          setSelectedFiles(updatedFiles);
                        }}
                        sx={{ color: "#ff1744" }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              )
            )}
          </Box>
        )}

        <Box
          sx={{ position: "relative", display: "flex", alignItems: "center" }}
        >
          <TextField
            className="message-input"
            fullWidth
            variant="outlined"
            placeholder={t("write_message_placeholder")}
            multiline
            disabled={isUploadingDocuments || isMessagePending}
            minRows={textInputRows}
            maxRows={3}
            value={userMessage}
            onChange={handleTextChange}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !event.altKey && !event.shiftKey) {
                event.preventDefault(); // Prevent line break

                if (!isMessagePending && userMessage.trim()) submit();

                // if (isStepActive(2)) {
                //   dispatch(setIsLoadingOnboarding(true));


                //   checkAndMoveToStep(
                //     ".assistant-message-container",
                //     setCurrentStepIndex, // Function to set Joyride step
                //     3 // Index of the next step
                //   );

                //   setTimeout(() => {
                //     dispatch(setIsLoadingOnboarding(true));
                //     // setCurrentStepIndex(index + 1); // Move to the next step
                //   }, 10000); // Simulate 3 seconds of loading
                // }
              } else if (
                (event.altKey || event.shiftKey) &&
                event.key === "Enter"
              ) {
                setUserMessage((prev) => `${prev}\n`); // Allows line breaks when Alt or Shift is pressed with Enter
              }
            }}
            ref={textFocusRef}
            dir={currentLang === "ar" ? "rtl" : "ltr"} // Add RTL support
            InputProps={{
              sx: {
                paddingRight:
                  currentLang === "ar" ? windowWidth / 480 : windowWidth / 105,
                paddingLeft:
                  currentLang === "ar" ? windowWidth / 105 : windowWidth / 480,
              },
            }}
          />
          <IconButton
            onClick={() => {
              if (isUploadingDocuments && uploadController) {
                if (uploadController) {
                  uploadController.abort();
                  setUploadController(null);
                  setIsUploadingDocuments(false);
                  setSelectedFiles([]);
                  setIsMessagePending(false);
                  enqueueSnackbar(t("conversation_stopped"), {
                    variant: "info",
                  });
                }
              } else if (eventSourceRef.current) {
                eventSourceRef.current.close();
                eventSourceRef.current = null;
                setIsMessagePending(false);
                setIsUploadingDocuments(false);
                setSelectedFiles([]);
                setUserMessage("");
                enqueueSnackbar(t("conversation_stopped"), { variant: "info" });
              } else {
                submit();
              }
            }}
            // disabled={isMessagePending || !userMessage.trim()}
            disabled={Boolean(!userMessage.trim() && !eventSourceRef.current)}
            sx={{
              position: "absolute",
              right: currentLang === "ar" ? "unset" : "10px", // Adjust for RTL
              left: currentLang === "ar" ? "10px" : "unset", // Adjust for RTL
              // bottom: "10px", // Adjust as needed
              backgroundColor: "white", // Optional: background for better visibility
              zIndex: 1, // Make sure it’s on top of the text input
            }}
          >
            <img
              src={
                !userMessage.trim() && !eventSourceRef.current
                  ? DisabledSendIcon
                  : isMessagePending
                  ? StopIcon
                  : SendIcon
              }
              alt="Send"
              style={{ height: windowWidth / 60 }}
            />
          </IconButton>

          <IconButton
            disabled={Boolean(eventSourceRef.current || isUploadingDocuments)}
            onClick={() => fileInputRef.current.click()}
            sx={{
              position: "absolute",
              right: currentLang === "ar" ? "unset" : "50px",
              left: currentLang === "ar" ? "50px" : "unset",
              backgroundColor: "white",
              zIndex: 1,
            }}
          >
            <UploadFile sx={{ fontSize: windowWidth / 70 }} />
          </IconButton>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept="application/pdf, text/plain"
            multiple
          />
        </Box>
      </Box>
    </>
  );
};

export default Chat;
