import React from "react";
import ReactGA from "react-ga4";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Check if the app is in production mode
const isProduction = process.env.REACT_APP_NODE_ENV === "production";

if (isProduction) {
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};

  // Initialize Google Analytics only in production
  ReactGA.initialize("G-B4WZ4J3HRJ");
} else {
  console.log("Google Analytics is disabled in development mode.");
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
