import { fromBackendDocumentToFrontend } from "../utils/utils";
import { backendUrl } from "../config";
import { BrowserRouter as Navigate } from "react-router-dom";
import { store } from "../redux/store";
import { logout } from "../redux/actions/authAction";
import { enqueueSnackbar } from 'notistack';


class BackendClient {
  constructor(dispatch) {
    this.dispatch = dispatch; // Store the dispatch function
    this.isSnackbarVisible = false;
  }

  saveUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  getUser() {
    const userString = localStorage.getItem("user");
    return userString ? JSON.parse(userString) : null;
  }

  removeUser() {
    localStorage.removeItem("user");
  }

  saveToken(token) {
    localStorage.setItem("authToken", JSON.stringify(token));
  }

  deleteToken() {
    localStorage.removeItem("authToken");
  }

  getToken() {
    // const tokenString = localStorage.getItem("authToken");
    // if (!tokenString) return null;
    // return JSON.parse(tokenString);
    const state = store.getState();
    const token = state.auth.user.token;
    return token;
  }

  authHeader() {
    try {
      const tokenPayload = this.getToken();
      if (!tokenPayload) return undefined;
      return {
        Authorization: `${tokenPayload.token_type
          .charAt(0)
          .toUpperCase()}${tokenPayload.token_type.slice(1)} ${
          tokenPayload.access_token
        }`,

        "Content-Type": "application/json",
      };
    } catch {
      return {
        "Content-Type": "application/json",
      };
    }
  }

  // Show a snackbar if one isn't already visible
  showSnackbar(message, variant = 'error') {
    if (!this.isSnackbarVisible) {
      this.isSnackbarVisible = true; // Set flag to true
      enqueueSnackbar(message, {
        variant,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        autoHideDuration: null, // Don't auto-hide
        persist: true, // Keep the snackbar visible
        onClose: () => {
          this.isSnackbarVisible = false; // Reset flag when snackbar is closed
        },
      });
    }
  }

  async handleResponse(res) {
    if (res.status === 503) {
      // Server is in maintenance
      this.showSnackbar("Le serveur est actuellement en maintenance. Veuillez réessayer plus tard.");
      throw new Error("Le serveur est en maintenance");
    }

    if (res.status === 403) {
      console.log("Ce compte n'est pas autorisé par les administrateurs, veuillez contacter le support");
      this.dispatch(logout());
      window.alert("Utilisateur non autorisé. Veuillez contacter le support.");
    }

    if (res.status === 401) {
      console.log("La session a expiré ou le jeton est invalide. Déconnexion en cours.");
      this.dispatch(logout());
    }
    
    if (!res.ok) {
      const errorData = await res.json();
      throw {
        status: res.status,
        message: errorData.detail || `HTTP error! status: ${res.status}`,
      };
    }

    return res;
  }

  async get(endpoint) {
    const url = backendUrl + endpoint;
    const headers = this.authHeader();

    try {
      const res = await fetch(url, { headers });
      return await this.handleResponse(res);
    } catch (error) {
      if (error.message === "Failed to fetch") {
        // Network error or server down
        this.showSnackbar("Impossible de se connecter au serveur. Veuillez vérifier votre connexion Internet ou réessayer plus tard.");
      }
      throw error;
    }
  }

  async post(endpoint, body) {
    const url = backendUrl + endpoint;
    const headers = this.authHeader();

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: headers || { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      return await this.handleResponse(res);
    } catch (error) {
      if (error.message === "Failed to fetch") {
        // Network error or server down
        this.showSnackbar("Unable to connect to the server. Please check your internet connection or try again later.");
      }
      throw error;
    }
  }


  // async sendMessage(conversationId, messageContent) {
  //   const endpoint = `api/conversation/${conversationId}/message`;
  //   const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //   const payload = { user_message: messageContent, timezone };
  //   const res = await this.post(endpoint, payload);

  //   if (!res.ok) {
  //     throw new Error(
  //       `Failed to send message: HTTP error! status: ${res.status}`
  //     );
  //   }
  //   return res.json();
  // }

  async updateBoarded(userId, isBoarded) {
    const endpoint = "api/user/update_is_boarded"; // API endpoint
    const payload = {
      user_id: userId,
      is_boarded: isBoarded,
    };

    try {
      const res = await this.post(endpoint, payload);

      if (!res.ok) {
        const errorData = await res.json();

        // Handle unexpected response formats
        if (Array.isArray(errorData)) {
          throw new Error(
            `Backend returned errors: ${JSON.stringify(errorData)}`
          );
        } else {
          throw new Error(
            errorData.detail ||
              `Failed to update 'is_boarded': HTTP status ${res.status}`
          );
        }
      }

      return await res.json(); // Return the success response
    } catch (error) {
      console.error("Error while updating 'is_boarded':", error.message);
      throw error;
    }
  }

  async login(email, password) {
    const endpoint = "api/auth/login";
    const payload = { email, password };

    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async logout() {
    this.removeUser();
    this.deleteToken();
    // await Router.replace("/login"); // Redirige vers la page de connexion
    Navigate("/login");
  }

  // Add to BackendClient class in backendClient.js
  async forgotPassword(email) {
    const endpoint = "api/auth/forgot-password";
    const payload = { email };

    const res = await this.post(endpoint, payload);

    if (!res.ok) {
      const errorData = await res.json();
      throw new Error(
        errorData.detail || `Failed to send password reset email: ${res.status}`
      );
    }

    return res;
  }

  async resetPassword(token, newPassword) {
    const endpoint = "api/auth/reset-password";
    const payload = { token, new_password: newPassword };

    const res = await this.post(endpoint, payload);

    if (!res.ok) {
      const errorData = await res.json();
      throw new Error(
        errorData.detail ||
          `Failed to reset password: HTTP status ${res.status}`
      );
    }

    return res;
  }

  async signUp(
    last_name,
    first_name,
    organization_name,
    phone_number,
    email,
    password
  ) {
    const endpoint = "api/auth/register";
    const payload = {
      last_name,
      first_name,
      organization_name,
      phone_number,
      email,
      password,
    };
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async createConversation(user_id) {
    const endpoint = "api/conversation/create";
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const payload = { user_id, timezone };

    const res = await this.post(endpoint, payload);

    if (!res.ok) {
      const errorData = await res.json();
      throw new Error(
        errorData.detail ||
          `Échec de la création de la conversation : statut HTTP ${res.status}`
      );
    }

    const data = await res.json();
    return data;
  }

  async fetchUser(userId) {
    const endpoint = "api/auth/fetch-user"; // API endpoint for fetching user
    const payload = { user_id: userId };

    try {
      const res = await this.post(endpoint, payload); // Reusing the POST method

      if (!res.ok) {
        const errorData = await res.json();

        // Handle unauthorized or token issues
        if (res.status === 401 || res.status === 403) {
          console.error("Token is invalid or expired. Logging out...");
          this.dispatch(logout()); // Dispatch logout action
          throw new Error(errorData.detail || "Invalid or expired token.");
        }

        // Handle other errors
        throw new Error(
          errorData.detail || `Failed to fetch user: HTTP status ${res.status}`
        );
      }

      // Parse and return the successful response
      const data = await res.json();

      return data;
    } catch (error) {
      console.error("Error while fetching user:", error.message);
      // throw error; // Re-throw the error for further handling
    }
  }

  async fetchUsersByOrganization(organizationName) {
    const endpoint = `api/user/organization/${organizationName}`;
    const res = await this.get(endpoint);

    if (!res.ok) {
      throw new Error(`Failed to fetch users: HTTP status ${res.status}`);
    }

    const data = await res.json();
    return data;
  }

  async shareConversation(conversationId, userIds) {
    const endpoint = `api/conversation/share`;
    const payload = { conversation_id: conversationId, users_ids: userIds };
    console.log("payload", payload);
    const res = await this.post(endpoint, payload);

    if (!res.ok) {
      throw new Error(`Failed to share conversation: ${res.status}`);
    }
    return res;
  }

  async deleteConversations(conversationIds) {
    const endpoint = `api/conversation/delete`;
    const payload = { conversation_ids: conversationIds };
    const res = await this.post(endpoint, payload); // Assuming you have a POST method
    console.log("res", res);
    if (!res.ok) {
      throw new Error(`Failed to delete conversations: ${res.status}`);
    }
    return res;
  }

  async fetchUserConversations(user_id, limit = 5, offset = 0) {
    const endpoint = `api/conversation/${user_id}/conversations?limit=${limit}&offset=${offset}`;
    try {
      const res = await this.get(endpoint);

      if (!res.ok) {
        // Handle the error by throwing an exception
        throw new Error(
          `Échec de la récupération des conversations : statut HTTP ${res.status}`
        );
      }

      const data = await res.json();

      return data.map((conversation) => ({
        id: conversation.id,
        title: conversation.title,
        users: conversation.users,
        updated_at: conversation.updated_at,
        created_at: conversation.created_at,
        // messages: conversation.messages,
        // documents: fromBackendDocumentToFrontend(conversation.documents),
      }));
    } catch (error) {
      console.error(`Erreur : ${error.message}`);
      throw error; // Propagate the error to be handled by the caller
    }
  }

  async fetchConversation(id) {
    const endpoint = `api/conversation/${id}`;
    const res = await this.get(endpoint);

    if (!res.ok) {
      // throw new Error(
      //   `Failed to fetch conversation: HTTP status ${res.status}`
      // );
      return [];
    }

    const data = await res.json();

    // Reverse messages only if first message is from assistant
    const messages =
      data.messages.length > 0 && data.messages[0].role === "assistant"
        ? data.messages.reverse()
        : data.messages;

    return {
      ...data,
      messages,
      // messages: data.messages.reverse(),
      // messages: data.messages,
      documents: fromBackendDocumentToFrontend(data.documents),
    };
  }

  async getDocumentSas(containerName, blobName) {
    const endpoint = `api/document/generate-sas`;
    const body = { containerName, blobName };
    const res = await this.post(endpoint, body);
    const data = await res.json();
    return data;
  }

  async translateHtml(fileUrl, targetLanguage) {
    const endpoint = "api/document/translate-html"; // Updated endpoint path
    const payload = {
      file_url: fileUrl,
      target_language: targetLanguage,
    };

    try {
      const res = await this.post(endpoint, payload);
      console.log("res", res);

      if (res.status === 403) {
        console.log(
          "This account is not allowed by admins, please contact support"
        );
        this.dispatch(logout());
        window.alert(
          "Utilisateur non autorisé. Veuillez contacter le support."
        );
        throw new Error("Unauthorized access");
      }

      if (res.status === 401) {
        console.log(
          "Session has expired or the token is invalid. Logging out."
        );
        this.dispatch(logout());
        window.alert(
          "Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté."
        );
        throw new Error("Session expired");
      }

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail ||
            `Failed to translate document: HTTP status ${res.status}`
        );
      }

      return await res.json();
    } catch (error) {
      console.error("Translation error:", error);
      throw error;
    }
  }

  async fetchDocuments() {
    const endpoint = `api/document/`;
    const res = await this.get(endpoint);
    const data = await res.json();
    const docs = fromBackendDocumentToFrontend(data);
    return docs;
  }

  async post_form(endpoint, formData) {
    const url = backendUrl + endpoint;
    const res = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return res;
  }

  async upload_documents(files) {
    const endpoint = `api/document/upload`;
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file);
    });

    const res = await this.post_form(endpoint, formData);
    const data = await res.json();

    const docs = fromBackendDocumentToFrontend(data);
    return docs;
  }

  async exportDocument(content, fileType, filename) {
    try {
      if (fileType === "pdf") {
        // For direct PDF downloads
        const response = await fetch(content);
        if (!response.ok) {
          throw new Error("Failed to fetch PDF content");
        }
        return await response.blob();
      } else {
        // For HTML to PDF conversion
        const url = backendUrl + "api/document/export";
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const response = await fetch(url, {
          method: "POST",
          headers: this.authHeader(),
          body: JSON.stringify({
            html_content: content,
            export_to: "pdf",
            timezone,
            filename,
          }),
        });

        if (response.status === 403) {
          console.log(
            "This account is not allowed by admins, please contact support"
          );
          this.dispatch(logout());
          throw new Error("unauthorized_access");
        }

        if (response.status === 401) {
          console.log("Session has expired or the token is invalid");
          this.dispatch(logout());
          throw new Error("session_expired");
        }

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.blob();
      }
    } catch (error) {
      console.error("Export error:", error);
      throw error;
    }
  }

  async sendSupport(
    supportObject,
    supportDescription,
    attachedFile,
    userEmail
  ) {
    const url = `${backendUrl}api/user/submit_support`;

    try {
      // Prepare FormData for file upload
      const formData = new FormData();
      formData.append("supportObject", supportObject);
      formData.append("supportDescription", supportDescription);
      formData.append("userEmail", userEmail);

      if (attachedFile) {
        formData.append("attachedFile", attachedFile);
      }

      console.log("formData", formData);

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      const headers = this.authHeader();

      console.log("headers", headers);
      const res = await fetch(url, {
        method: "POST",
        // headers: headers, // Ensure appropriate headers for authentication
        body: formData,
      });

      console.log("res", res);

      if (res.status === 403) {
        console.log(
          "This account is not allowed by admins, please contact support"
        );
        this.dispatch(logout());
        window.alert(
          "Utilisateur non autorisé. Veuillez contacter le support."
        );
        throw new Error("Unauthorized access");
      }

      if (res.status === 401) {
        console.log(
          "Session has expired or the token is invalid. Logging out."
        );
        this.dispatch(logout());
        window.alert(
          "Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté."
        );
        throw new Error("Session expired");
      }

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail ||
            `Failed to send support: HTTP status ${res.status}`
        );
      }

      const data = await res.json();
      return data;
    } catch (error) {
      console.error("Error while sending support:", error.message);
      throw error;
    }
  }

  async exportMessages(messages, export_to, email) {
    const url = backendUrl + "api/document/convert";
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = this.authHeader();
    const res = await fetch(url, {
      method: "POST",
      headers: headers || { "Content-Type": "application/json" },
      body: JSON.stringify({
        messages,
        export_to,
        timezone,
        email,
      }),
    });

    if (res.status === 403) {
      console.log(
        "This account is not allowed by admins, please contact support"
      );
      this.dispatch(logout());
      window.alert("Utilisateur non autorisé. Veuillez contacter le support.");
    }

    if (res.status === 401) {
      console.log("Session has expired or the token is invalid. Logging out.");
      // Dispatch the Redux logout action here
      this.dispatch(logout());
      window.alert(
        "Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté."
      );
    }

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const blob = await res.blob();
    return blob;
  }

  async downloadDocument(documentPath) {
    const headers = this.authHeader();
    const url =
      backendUrl +
      `api/document/gcp/{document_id}?document_path=${documentPath}`;

    const res = await fetch(url, {
      method: "POST",
      headers: headers || { "Content-Type": "application/json" },
      body: "",
    });

    if (res.status === 403) {
      console.log(
        "This account is not allowed by admins, please contact support"
      );
      this.dispatch(logout());
      window.alert("Utilisateur non autorisé. Veuillez contacter le support.");
    }

    if (res.status === 401) {
      console.log("Session has expired or the token is invalid. Logging out.");
      // Dispatch the Redux logout action here
      this.dispatch(logout());
      window.alert(
        "Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté."
      );
    }

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const blob = await res.blob();
    return blob;
  }

  //   DASHBOARD
  async getConversationsAndUsers(organization_name = null) {
    const endpoint = `api/conversation/conversations-and-users`;

    const body = {
      organization_name: organization_name || null, // Send null if no organization name
    };

    const res = await this.post(endpoint, body); // Use POST method now

    if (!res.ok) {
      throw new Error(
        `Failed to fetch conversations and users: HTTP status ${res.status}`
      );
    }

    const data = await res.json();
    return {
      totalConversations: data.total_conversations,
      totalUsers: data.total_users,
    };
  }

  async getConversationsCountByUser(user_id = null) {
    const endpoint = `api/conversation/conversations-count`;

    const body = {
      user_id: user_id || null, // Send null if no organization name
    };

    const res = await this.post(endpoint, body); // Use POST method now

    if (!res.ok) {
      throw new Error(
        `Failed to fetch conversations and users: HTTP status ${res.status}`
      );
    }

    const data = await res.json();
    return {
      totalConversations: data.total_conversations,
    };
  }

  // Fetch conversations over time
  async getConversationsOverTime(organization_name = null) {
    const endpoint = "api/conversation/conversations-over-time";

    const body = {
      organization_name: organization_name || null, // Send null if no organization name
    };

    const res = await this.post(endpoint, body); // Use POST method now

    if (!res.ok) {
      throw new Error(
        `Failed to fetch conversations over time: HTTP status ${res.status}`
      );
    }

    const data = await res.json();
    return data.map((conversationData) => ({
      id: conversationData.id,
      data: conversationData.data,
    }));
  }

  // Fetch total searches and users
  async getSearchesAndUsers(organization_name = null) {
    const endpoint = `api/search/searches-and-users`;

    const body = {
      organization_name: organization_name || null, // Send null if no organization name
    };

    const res = await this.post(endpoint, body); // Use POST method

    if (!res.ok) {
      throw new Error(
        `Failed to fetch searches and users: HTTP status ${res.status}`
      );
    }

    const data = await res.json();
    return {
      totalSearchs: data.total_searches,
      totalUsersSearchs: data.total_users,
    };
  }

  // Fetch searches over time
  async getSearchesOverTime(organization_name = null) {
    const endpoint = "api/search/searches-over-time";

    const body = {
      organization_name: organization_name || null, // Send null if no organization name
    };

    const res = await this.post(endpoint, body); // Use POST method

    if (!res.ok) {
      throw new Error(
        `Failed to fetch searches over time: HTTP status ${res.status}`
      );
    }

    const data = await res.json();
    return data.map((searchData) => ({
      id: searchData.id,
      data: searchData.data,
    }));
  }

  // Serarch

  async searchUserConversations(user_id, searchTerm) {
    const endpoint = `api/conversation/${user_id}/conversations/search?search_term=${encodeURIComponent(
      searchTerm
    )}`;
    const res = await this.get(endpoint);

    if (!res.ok) {
      throw new Error(
        `Failed to search conversations: HTTP status ${res.status}`
      );
    }

    return res;
  }

  async search(query, userId, mode) {
    const endpoint = "api/search/"; // Update the endpoint as needed
    const payload = { query, user_id: userId, mode: mode }; // Add user_id to the payload

    try {
      const res = await this.post(endpoint, payload);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail || `Failed to search: HTTP status ${res.status}`
        );
      }

      return res;
    } catch (error) {
      // Further handle any unexpected errors
      throw new Error(
        error.message || "An unexpected error occurred while searching"
      );
    }
  }

  async updateMessageLike(message_id, value) {
    const endpoint = `api/conversation/update-message-like/`; // Adjust the endpoint as per your API route

    const payload = {
      message_id: message_id,
      value: value,
    };
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async getRecentSearches(userId, offset = 0) {
    const endpoint = `api/search/recent?offset=${offset}&user_id=${userId}`;
    const res = await this.get(endpoint);
    if (!res.ok)
      throw new Error(`Failed to fetch recent searches: ${res.status}`);
    return res;
  }

  async getAutocompleteResults(query) {
    const endpoint = `api/search/autocomplete?query=${encodeURIComponent(
      query
    )}`;
    const res = await this.get(endpoint);
    if (!res.ok)
      throw new Error(`Failed to fetch autocomplete results: ${res.status}`);
    return res;
  }

  async updatePrompt(userId, type, prompt_text) {
    const endpoint = `api/prompt/update`;
    const payload = { user_id: userId, type, prompt_text }; // Use user_id and type in the payload

    const headers = this.authHeader();
    const res = await fetch(backendUrl + endpoint, {
      method: "PUT",
      headers: headers || { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    if (res.status === 403) {
      console.log(
        "This account is not allowed by admins, please contact support"
      );
      this.dispatch(logout());
      window.alert("Utilisateur non autorisé. Veuillez contacter le support.");
    }

    if (res.status === 401) {
      console.log("Session has expired or the token is invalid. Logging out.");
      // Dispatch the Redux logout action here
      this.dispatch(logout());
      window.alert(
        "Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté."
      );
    }

    if (!res.ok) {
      const errorData = await res.json();
      throw {
        status: res.status,
        message:
          errorData.detail ||
          `Failed to update prompt: HTTP status ${res.status}`,
      };
    }

    return res.json(); // Return the updated prompt
  }

  async getPrompt(userId, promptType = null) {
    const endpoint = `api/prompt/get?user_id=${userId}${
      promptType ? `&prompt_type=${encodeURIComponent(promptType)}` : ""
    }`;

    const res = await this.get(endpoint);

    if (!res.ok) {
      throw new Error(`Failed to fetch prompt: HTTP status ${res.status}`);
    }

    return res.json(); // Parse and return the JSON data
  }
}

export const backendClient = new BackendClient(store.dispatch);
