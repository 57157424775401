import React, { useState, useEffect } from "react";
import CitationDisplay from "./Citation"; // Adjust the import paths as needed
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { updateMessageLike } from "../../../redux/actions/conversationAction";
import useConversationsStore from "./useConversationsStore";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const Citations = ({ messageId, citations }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(null);
  // const [isLikeDisabled, setIsLikeDisabled] = useState(false);

  const { messages, setMessages } = useConversationsStore();
  

  useEffect(() => {
    const message = messages.find((msg) => msg.id === messageId);
    if (message?.like?.value === "False") {
      setSelected("down");
      // setIsLikeDisabled(true);
    } else if (message?.like?.value === "True") {
      setSelected("up");
      // setIsLikeDisabled(true);
    }
  }, [messageId, messages]);



  const handleSelection = async (choice) => {
    const newValue = selected === choice ? null : choice;



    // Update selected state
    setSelected(newValue);

    // Update like status in backend
    const likeValue =
      newValue === "up" ? "True" : newValue === "down" ? "False" : null;
    await dispatch(updateMessageLike(messageId, likeValue));

    // Update messages in store
    const updatedMessages = messages.map((message) =>
      message.id === messageId
        ? {
            ...message,
            like: { value: likeValue },
          }
        : message
    );
    setMessages(updatedMessages);

    // Google Analytics event for liking/disliking
    ReactGA.event({
      category: "Message Feedback",
      action: choice === "up" ? "Liked Message" : "Disliked Message",
      label: `Message ID: ${messageId}`,
      value: newValue === "up" ? 1 : newValue === "down" ? -1 : 0,
    });
  };

  const toggleCitations = () => {
    setShow(!show);

    // Google Analytics event for toggling citations
    ReactGA.event({
      category: "Citations",
      action: show ? "Collapsed Citations" : "Expanded Citations",
      label: `Message ID: ${messageId}`,
    });
  };

  return (
    <div className="assistant-message-citations mt-2 mb-2 ml-9 w-11/12 pr-1">
      <div className="assistant-message-like flex items-center">
        <IconButton
          onClick={async () => await handleSelection("up")}
          aria-label="thumb up"
          color={selected === "up" ? "primary" : "default"}
        >
          <ThumbUpIcon style={{ fontSize: 18 }} />
        </IconButton>
        <IconButton
          onClick={async () => await handleSelection("down")}
          aria-label="thumb down"
          color={selected === "down" ? "primary" : "default"}
        >
          <ThumbDownIcon style={{ fontSize: 18 }} />
        </IconButton>
      </div>

      <div
        className="mb-2 cursor-pointer text-xs underline flex items-center font-nunito"
        style={{ color: "#BE2165" }}
        onClick={toggleCitations}
      >
        {t("references")}
        <div className="px-2 py-2">
          {!show ? (
            <ArrowDropDownIcon />
          ) : (
            <ArrowDropUpIcon className="-rotate-90" />
          )}
        </div>
      </div>

      {!show && (
        <div className="mb-10 justify-center space-y-2 pr-3">
          {citations.map((citation, index) => (
            <CitationDisplay key={index} citation={citation} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Citations;
