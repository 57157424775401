// userReducer.js

import {
  FETCH_PROMPTS_FAILURE,
  FETCH_PROMPTS_REQUEST,
  FETCH_PROMPTS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  UPDATE_PROMPT_FAILURE,
  UPDATE_PROMPT_REQUEST,
  UPDATE_PROMPT_SUCCESS,
  SET_ACTIVE_STEP,
  RESET_ACTIVE_STEP,
  SET_LOADING_ONBOARDING,
} from "../actions/types";

const initialState = {
  organization_prompt: null,
  users: [],
  loading: false,
  error: null,
  activeStep: 0,
  shouldOnboarding: true,
  isLoadingOnboarding: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_PROMPT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PROMPT_SUCCESS:
      return {
        ...state,
        loading: false,
        organization_prompt: action.payload,
        error: null,
      };
    case UPDATE_PROMPT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FETCH_PROMPTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PROMPTS_SUCCESS:
      return {
        ...state,
        loading: false,
        organization_prompt: action.payload,
      };
    case FETCH_PROMPTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };



    case SET_LOADING_ONBOARDING:
      return {
        ...state,
        isLoadingOnboarding: action.payload,
      };

    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };

    case RESET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: 0,
      };

    default:
      return state;
  }
};

export default userReducer;
