import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Box } from "@mui/material";
import { loginWithToken } from "../../redux/actions/authAction";

const ConfirmAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      // Dispatch the login action with the token
      dispatch(loginWithToken(token, navigate));
    }
  }, [dispatch, token, navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default ConfirmAccount;
