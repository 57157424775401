// userAction.js
import { backendClient } from "../../api/backend";
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  UPDATE_PROMPT_REQUEST,
  UPDATE_PROMPT_SUCCESS,
  UPDATE_PROMPT_FAILURE,
  FETCH_PROMPTS_REQUEST,
  FETCH_PROMPTS_SUCCESS,
  FETCH_PROMPTS_FAILURE,
  SET_ACTIVE_STEP,
  RESET_ACTIVE_STEP,
  SET_LOADING_ONBOARDING,
} from "../actions/types";



export const setIsLoadingOnboarding = (value) => (dispatch) => {

  dispatch({
    type: SET_LOADING_ONBOARDING,
    payload: value,
  });
};

// Action to set the active step
export const setActiveStep = (step) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_STEP,
    payload: step,
  });
};

// Action to reset the active step
export const resetActiveStep = () => (dispatch) => {
  dispatch({
    type: RESET_ACTIVE_STEP,
  });
};

export const fetchUsersByOrganization =
  (organizationName) => async (dispatch) => {
    dispatch({ type: FETCH_USERS_REQUEST });

    try {
      const users = await backendClient.fetchUsersByOrganization(
        organizationName
      );
      dispatch({
        type: FETCH_USERS_SUCCESS,
        payload: users,
      });
    } catch (error) {
      dispatch({
        type: FETCH_USERS_FAILURE,
        payload: error.message,
      });
    }
  };

export const fetchPrompts = (userId, promptType) => async (dispatch) => {
  dispatch({ type: FETCH_PROMPTS_REQUEST });

  try {
    const prompts = await backendClient.getPrompt(userId, promptType);
    dispatch({
      type: FETCH_PROMPTS_SUCCESS,
      payload: prompts,
    });
  } catch (error) {
    dispatch({
      type: FETCH_PROMPTS_FAILURE,
      payload: error.message,
    });
  }
};
export const updatePrompt = (userId, type, promptText) => async (dispatch) => {
  dispatch({ type: UPDATE_PROMPT_REQUEST });

  try {
    const updatedPrompt = await backendClient.updatePrompt(
      userId,
      type,
      promptText
    );
    dispatch({
      type: UPDATE_PROMPT_SUCCESS,
      payload: updatedPrompt,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PROMPT_FAILURE,
      payload: error.message,
    });
  }
};
