import React, { useEffect, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  Tabs,
  Tab,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useState } from "react";
// import SettingsScreen from './SettingsScreen';
import { colors } from "../../utils/colors";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/authAction";
import { fetchPrompts, updatePrompt } from "../../redux/actions/userAction";
import { useSnackbar } from "notistack";
import CustomButton from "../../components/CustomButton";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { backendClient } from "../../api/backend";

const SettingsScreen = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState(0);
  const [promptText, setPromptText] = useState("");
  const [isSavingPrompt, setIsSavingPrompt] = useState(false);
  const user = useSelector((state) => state.auth?.user?.user);
  const organization_prompt = useSelector(
    (state) => state.user?.organization_prompt
  );
  const [language, setLanguage] = useState(currentLang); // Default to French

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);

    // Google Analytics event for tab changes
    ReactGA.event({
      category: "Settings",
      action: "Tab Change",
      label: `Tab Index: ${newValue}`,
    });
  };

  const handleLogout = () => {
    dispatch(logout());

    // Google Analytics event for logging out
    ReactGA.event({
      category: "Settings",
      action: "Logged Out",
      label: `User ID: ${user.id}`,
    });
  };

  const handlePromptChange = (event) => {
    setPromptText(event.target.value);
  };

  const handleSavePrompt = async () => {
    setIsSavingPrompt(true); // Start loading
    try {
      await dispatch(updatePrompt(user.id, "organization", promptText));
      enqueueSnackbar("Prompt mis-à-jour avec succès!", {
        variant: "success",
      });

      // Google Analytics event for saving prompt
      ReactGA.event({
        category: "Settings",
        action: "Saved Prompt",
        label: `User ID: ${user.id}`,
      });
    } catch (error) {
      console.error("Failed to update prompt:", error);
      enqueueSnackbar("Erreur lors de l'enregistrement.", {
        variant: "error",
      });
    }
    setIsSavingPrompt(false); // Stop loading
  };

  useEffect(() => {
    dispatch(fetchPrompts(user.id, "organization_prompt"));
  }, [user]);

  useEffect(() => {
    setPromptText(organization_prompt?.prompt_text);
  }, [organization_prompt]);

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage); // Update the state for the selected language
    i18n.changeLanguage(newLanguage); // Change language in i18next
    enqueueSnackbar(
      t("language_changed_message", {
        language:
          newLanguage === "fr"
            ? t("language_french")
            : newLanguage === "ar"
            ? t("language_arabic")
            : t("language_english"),
      }),
      {
        variant: "info",
      }
    );

    // Google Analytics event for language change
    ReactGA.event({
      category: "Settings",
      action: "Language Changed",
      label: `New Language: ${newLanguage}`,
    });
  };

  const [supportObject, setSupportObject] = useState(""); // Support object dropdown value
  const [supportDescription, setSupportDescription] = useState(""); // Description text
  const [attachedFile, setAttachedFile] = useState(null); // Attached file
  const fileInputRef = useRef(null);
  const [isSendingSupport, setIsSendingSupport] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSupportSubmit = async () => {
    setIsSendingSupport(true);
    const newErrors = {};

    // Validate fields
    if (!supportObject) {
      newErrors.supportObject = t("error_support_object_required");
    }
    if (!supportDescription) {
      newErrors.supportDescription = t("error_support_description_required");
    }

    setErrors(newErrors);

    // Stop submission if there are errors
    if (Object.keys(newErrors).length > 0) return;

    const userEmail = user.email;
    console.log("Submitting support request:", {
      supportObject,
      supportDescription,
      attachedFile,
      userEmail,
    });

    try {
      const response = await backendClient.sendSupport(
        supportObject,
        supportDescription,
        attachedFile,
        userEmail
      );
      console.log("response", response);

      enqueueSnackbar(t("support_request_submitted"), {
        variant: "success",
      });

      // Reset form on successful submission
      setSupportObject("");
      setSupportDescription("");
      setAttachedFile(null);
      setErrors({});
    } catch (error) {
      console.error("Error while submitting support request:", error);
      enqueueSnackbar(t("error_support_submission_failed"), {
        variant: "error",
      });
    }
    setIsSendingSupport(false);
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Safely call click() only if ref is set
    }
  };

  const handleFileChange = (e) => {
    setAttachedFile(e.target.files[0]);
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.bg_color,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        direction: currentLang === "ar" ? "rtl" : "ltr",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: "900px",
          padding: "32px",
          borderRadius: "16px",
          direction: currentLang === "ar" ? "rtl" : "ltr",
        }}
      >
        {/* Tabs for navigation */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="params screen tabs"
          centered
        >
          <Tab label={t("general_parameters")} />
          {/* <Tab label={t("user_management")} />
  <Tab label={t("support")} /> */}
          <Tab label={t("prompts")} />
          <Tab label={t("support")} />

          <Tab label={t("logout")} />
        </Tabs>

        {/* Content Box */}
        <Box sx={{ mt: 4 }}>
          {activeTab === 0 && (
            <Box>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                {t("user_information")}
              </Typography>

              {/* Information Fields */}
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    label={t("last_name")}
                    variant="outlined"
                    fullWidth
                    value={user.last_name} // Last name from user object
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    label={t("first_name")}
                    variant="outlined"
                    fullWidth
                    value={user.first_name} // First name from user object
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    label={t("email")}
                    variant="outlined"
                    fullWidth
                    value={user.email} // Email from user object
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    label={t("phone_number")}
                    variant="outlined"
                    fullWidth
                    value={user.phone_number} // Phone number from user object
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>

              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 4 }}
              >
                {t("language_label")}
              </Typography>

              <Box sx={{ mt: 3 }}>
                <FormControl fullWidth>
                  {/* <InputLabel id="language-select-label">
                    {t("language_label")}
                  </InputLabel> */}
                  <Select
                    labelId="language-select-label"
                    value={language}
                    onChange={handleLanguageChange}
                  >
                    <MenuItem value="fr">🇫🇷 {t("language_french")}</MenuItem>
                    <MenuItem value="ar">🇲🇦 {t("language_arabic")}</MenuItem>
                    <MenuItem value="en">🇺🇸 {t("language_english")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {/* Validation Button */}
              {/* <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    padding: "10px 40px",
                    borderRadius: "8px",
                    backgroundColor: "#E0E0E0",
                  }}
                >
                  Valider
                </Button>
              </Box> */}
            </Box>
          )}

          {/* {activeTab === 1 && (
            <Typography>Gestion des utilisateurs content goes here</Typography>
          )} */}
          {/* {activeTab === 2 && (
            <Typography>Support content goes here</Typography>
          )} */}

          {activeTab === 1 && (
            <Box>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                {t("prompt_organization")}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {t("prompt_description")}
              </Typography>

              {/* Multiline Text Input for the Prompt */}
              <TextField
                label={t("prompt_organization")}
                multiline
                rows={6}
                variant="outlined"
                fullWidth
                value={promptText}
                onChange={handlePromptChange}
                sx={{ mt: 2 }}
              />

              <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
                <CustomButton
                  text={t("save_prompt")}
                  outlined={false} // Filled button
                  loading={isSavingPrompt} // Pass loading state
                  onClick={handleSavePrompt} // Call the create conversation function
                  sx={{
                    padding: "10px 40px",
                    borderRadius: "8px",
                    // backgroundColor: "#1976d2",
                    color: "#fff",
                  }}
                />
              </Box>
            </Box>
          )}

          {activeTab === 2 && (
            <Box>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                {t("support_tab")}
              </Typography>

              {/* Dropdown to Select Support Object */}
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="support-object-label">
                  {t("support_object")}
                </InputLabel>
                <Select
                  labelId="support-object-label"
                  value={supportObject}
                  onChange={(e) => setSupportObject(e.target.value)}
                  label={t("support_object")}
                  error={!!errors.supportObject} // Mark field as invalid if there's an error
                >
                  <MenuItem value="Documents manquants">
                    {t("missing_documents")}
                  </MenuItem>
                  <MenuItem value="Problème technique">
                    {t("technical_issue")}
                  </MenuItem>
                  <MenuItem value="Conseil juridique">
                    {t("legal_advice")}
                  </MenuItem>
                  <MenuItem value="Amélioration">
                    {t("ameleration_support")}
                  </MenuItem>
                  <MenuItem value="Guide des procédures">
                    {t("procedure_guidance")}
                  </MenuItem>
                  <MenuItem value="Retour d'information sur le système">
                    {t("system_feedback")}
                  </MenuItem>
                  <MenuItem value="Autre">{t("other")}</MenuItem>
                </Select>
                {errors.supportObject && (
                  <Typography color="red" variant="body2">
                    {errors.supportObject}
                  </Typography>
                )}
              </FormControl>

              {/* Multiline Text Area for Description */}
              <TextField
                label={t("support_description")}
                multiline
                rows={6}
                variant="outlined"
                fullWidth
                value={supportDescription}
                onChange={(e) => setSupportDescription(e.target.value)}
                error={!!errors.supportDescription} // Mark field as invalid if there's an error
                sx={{ mt: 2 }}
              />
              {errors.supportDescription && (
                <Typography color="red" variant="body2">
                  {errors.supportDescription}
                </Typography>
              )}

              {attachedFile && (
                <p>
                  {t("selected_file")} {attachedFile.name}
                </p>
              )}

              {/* Attach File Icon and Send Button */}
              <Box
                sx={{ mt: 4, display: "flex", alignItems: "center", gap: 2 }}
              >
                {/* <Button variant="contained" component="label">
                  {t("attach_file")}
                  <input
                    type="file"
                    hidden
                    onChange={(e) => setAttachedFile(e.target.files[0])}
                  />
                </Button> */}

                <div>
                  <CustomButton
                    text={t("attach_file")}
                    outlined={true}
                    disabled={isSendingSupport}
                    sx={{
                      padding: "8px 40px",
                      borderRadius: "8px",
                      color: "black",
                      position: "relative",
                    }}
                    onClick={handleClick} // Trigger file input click
                  />
                  <input
                    type="file"
                    ref={fileInputRef} // Attach the ref to input
                    style={{ display: "none" }} // Hide the input
                    onChange={handleFileChange}
                  />
                </div>

                <CustomButton
                  text={t("send")}
                  // outlined={false} // Filled button
                  loading={isSendingSupport} // Pass loading state
                  onClick={handleSupportSubmit}
                  disabled={
                    !supportObject || !supportDescription || isSendingSupport
                  }
                  sx={{
                    padding: "10px 40px",
                    borderRadius: "8px",
                    // backgroundColor: "#1976d2",
                    color: "#fff",
                  }}
                />
              </Box>
            </Box>
          )}

          {activeTab === 3 && (
            <Box>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                {t("logout")}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {t("disconnect_confirmation")}
              </Typography>
              <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  size="large"
                  color="error"
                  onClick={handleLogout}
                  sx={{
                    padding: "10px 40px",
                    borderRadius: "8px",
                  }}
                >
                  {t("logout_ver")}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default SettingsScreen;
