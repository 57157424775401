import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CircularProgress } from "@mui/material";
import useConversationsStore from "./useConversationsStore";
import CitationDisplay from "./Citation";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const SubProcessDisplay = ({
  index,
  subProcesses,
  isOpen,
  toggleOpen,
  messageId,
  documents,
  showSpinner = true,
}) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const currentDirection = currentLang === "ar" ? "rtl" : "ltr";

  const subQuestions = [];

  subProcesses?.forEach((subProcess, subProcessIndex) => {
    if (subProcess.metadata_map?.sub_question) {
      subQuestions.push({
        subQuestion: subProcess.metadata_map?.sub_question,
        subProcessIndex,
        subQuestionIndex: subQuestions.length,
      });
    } else if (subProcess.metadata_map?.sub_questions) {
      subProcess.metadata_map?.sub_questions.forEach((subQuestion) => {
        subQuestions.push({
          subQuestion,
          subProcessIndex,
          subQuestionIndex: subQuestions.length,
        });
      });
    }
  });

  const decodeString = (encodedString) => {
    try {
      return JSON.parse(`"${encodedString}"`);
    } catch (e) {
      return encodedString;
    }
  };

  const capitalizedSentence = (sentence) => {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  };

  const handleToggle = () => {
    toggleOpen();
    ReactGA.event({
      category: "SubProcess",
      action: isOpen ? "Collapsed SubProcess View" : "Expanded SubProcess View",
      label: `Message ID: ${messageId}`,
    });
  };

  return (
    <div
      key={`${messageId}-sub-process`}
      className={`mt-4 rounded`}
      style={{ direction: currentDirection }}
    >
      <div
        className="flex w-max cursor-pointer items-center rounded p-1 font-nunito text-sm text-gray-90 hover:bg-gray-00"
        onClick={handleToggle}
      >
        {t("view_progress")}
        <div className="px-3 py-2">
          {isOpen ? (
            <ExpandMoreIcon
              className={currentLang === "ar" ? "rotate-180" : ""}
              style={{
                transform: currentLang === "ar" ? "rotate(0)" : "rotate(0)",
                transition: "transform 0.3s ease",
              }}
            />
          ) : (
            <ExpandMoreIcon
              className={
                currentLang === "ar" ? "-rotate-90 rotate-180" : "-rotate-90"
              }
              style={{
                transform:
                  currentLang === "ar" ? "rotate(90deg)" : "rotate(-90deg)",
                transition: "transform 0.3s ease",
              }}
            />
          )}
        </div>
      </div>

      {isOpen && (
        <>
          <div
            className="ml-4 border-l max-w-xl border-l-gray-30 pb-1 pl-4 font-nunito text-[11px] font-light text-gray-60"
            style={{ direction: currentDirection }}
          >
            <div>{t("question_received")}</div>
            {subQuestions.length > 0 && (
              <div key={`${messageId}-sub-process`} className="text-gray-60">
                <div>
                  {subQuestions.map(
                    ({ subQuestion, subQuestionIndex, subProcessIndex }) => {
                      const hasCitations = !!subQuestion.citations;
                      const loiCitations = subQuestion?.citations?.filter(
                        (cts) =>
                          cts?.doc_type?.toLowerCase() !== "jurisprudence"
                      );
                      const jpCitations = subQuestion?.citations?.filter(
                        (cts) =>
                          cts?.doc_type?.toLowerCase() === "jurisprudence"
                      );
                      return (
                        <div
                          className={`assistant-subprocess-${subQuestionIndex}`}
                          key={`${messageId}-${subProcessIndex}-${subQuestionIndex}`}
                        >
                          {t("generated_subquestion")} #{subQuestionIndex + 1}
                          <div className="flex mr-3 flex-col rounded border">
                            <div
                              className={`assistant-subprocess-question-${subQuestionIndex} rounded-t border-b bg-gray-00 p-2 font-bold text-gray-90`}
                            >
                              {decodeString(
                                capitalizedSentence(subQuestion.question)
                              )}
                            </div>
                            <div
                              className={`assistant-subprocess-answer-${subQuestionIndex} overflow-scroll p-2 text-[11px] font-light`}
                            >
                              {subQuestion.answer}
                            </div>

                            {hasCitations && (
                              <div
                                className={`assistant-subprocess-sources-${subQuestionIndex} mr-2 flex w-full overflow-x-scroll pl-2 flex-shrink-0`}
                              >
                                {loiCitations?.map(
                                  (citation, citationIndex) => {
                                    return (
                                      <CitationDisplay
                                        key={`${messageId}-${subProcessIndex}-${subQuestionIndex}-${citationIndex}`}
                                        citation={{
                                          page_content: citation.text,
                                          metadata: {
                                            chunk_position:
                                              citation.chunk_position,
                                            creation_date:
                                              citation.creation_date,
                                            doc_type: citation.doc_type,
                                            document_id: citation.document_id,
                                            file_name: citation.file_name,
                                            language: citation.language,
                                            long_title: citation.long_title,
                                            score: citation.score,
                                            section_title:
                                              citation.section_title,
                                            short_title: citation.short_title,
                                            total_chunks: citation.total_chunks,
                                            color: "yellow",
                                          },
                                        }}
                                      />
                                    );
                                  }
                                )}
                                {jpCitations?.map((citation, citationIndex) => {
                                  return (
                                    <CitationDisplay
                                      key={`${messageId}-${subProcessIndex}-${subQuestionIndex}-${citationIndex}`}
                                      citation={{
                                        page_content: citation.text,
                                        metadata: {
                                          chunk_position:
                                            citation.chunk_position,
                                          creation_date: citation.creation_date,
                                          doc_type: citation.doc_type,
                                          document_id: citation.document_id,
                                          file_name: citation.file_name,
                                          language: citation.language,
                                          long_title: citation.long_title,
                                          score: citation.score,
                                          section_title: citation.section_title,
                                          short_title: citation.short_title,
                                          total_chunks: citation.total_chunks,
                                          color: "yellow",
                                        },
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>

          {showSpinner && (
            <div className="ml-2.5 mt-1">
              <CircularProgress size={18} />
            </div>
          )}
          <div className="pb-2"></div>
        </>
      )}
    </div>
  );
};

export default SubProcessDisplay;
