import React, { useState, useEffect, forwardRef } from "react";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  Modal,
  List,
  ListItem,
  CircularProgress,
  Autocomplete,
  Chip,
  styled,
  ToggleButton,
  InputAdornment,
  ToggleButtonGroup,
  IconButton,
  Pagination,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../utils/colors";
import { styles } from "../../utils/styles";
import HtmlViewer from "../../components/html-viewer/HtmlViewer";
import useDocumentStore from "../ChatScreen/components/useDocumentStore";
import ReactGA from "react-ga4";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  searchCitations,
  fetchAutocompleteResults,
  fetchRecentSearches,
  searchSuccess,
  resetSearchStore,
  setSearchQuery,
} from "../../redux/actions/searchAction";
import debounce from "lodash/debounce";
import { isMobile, windowWidth } from "../../utils/utils";
import { fontSize } from "../../utils/fonts";
import { useTranslation } from "react-i18next";

// Custom Styled ToggleButton
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  flex: 1,
  backgroundColor: "#f0f0f0",
  color: "rgba(0, 0, 0, 0.87)",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "black",
  },
  padding: "6px 12px",
  "& .MuiToggleButton-label": {
    paddingTop: "6px",
  },
  "&:not(:first-of-type)": {
    borderRadius: theme.shape.borderRadius,
  },
  "&:first-of-type": {
    borderRadius: theme.shape.borderRadius,
  },
  border: "none",
  "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRight: "none",
  },
  "&.MuiToggleButtonGroup-grouped": {
    border: "none",
    "&:not(:first-of-type)": {
      marginLeft: 0,
    },
  },
  "&:hover": {
    backgroundColor: "#d6d6d6", // Slightly darker grey on hover for unselected buttons
  },
}));

const CustomListboxComponent = forwardRef(function CustomListboxComponent(
  { isFetchingMore, children, ...rest },
  ref
) {
  return (
    <div {...rest} ref={ref}>
      {children}
      {isFetchingMore && ( // Show the loading indicator if fetching more data
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "8px",
          }}
        >
          <CircularProgress size={20} />
        </Box>
      )}
    </div>
  );
});

const SearchScreen = () => {
  const { t, i18n } = useTranslation();
  const user_email = useSelector((state) => state.auth.user?.user?.email);
  const currentLang = i18n.language;

  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.auth.user?.user?.id);
  const {
    selectedCitation,
    setSelectedCitation,
    setLoading,
    isModalOpen,
    setIsModalOpen,
    showModalIfMobile,
    clear,
  } = useDocumentStore();
  const {
    citations,
    loading,
    error,
    recentSearches,
    autocompleteResults,
    searchQuery,
  } = useSelector((state) => state.search);
  // const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const itemsPerPage = 15; // Items per page

  const [offset, setOffset] = useState(0); // Offset for pagination
  // const [hasMore, setHasMore] = useState(true); // To check if there are more results to load
  const [mode, setMode] = useState("loi");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const open = useSelector((state) => state.navbar.isOpen);

  const [selectedDocTypes, setSelectedDocTypes] = useState([]);
  const docTypes = [
    { label: t("Décret"), color: "#388e3c" }, // Green
    { label: t("Dahir"), color: "#d32f2f" }, // Red
    { label: t("Arrêté"), color: "#1976d2" }, // Blue
    { label: t("Décret-loi"), color: "#0288d1" }, // Light Blue
    { label: t("Décision"), color: "#f57c00" }, // Orange
    { label: t("Code"), color: "#8e24aa" }, // Purple
    { label: t("Annexe"), color: "#0288d1" }, // Light Blue
    { label: t("Avis"), color: "#ffb300" }, // Yellow
    { label: t("Instruction"), color: "#43a047" }, // Dark Green
    { label: t("Loi"), color: "#f57c00" }, // Orange
    { label: t("Déclaration"), color: "#9c27b0" }, // Violet
    { label: t("Article"), color: "#0277bd" }, // Dark Blue
    { label: t("Convention"), color: "#0288d1" }, // Light Blue
    { label: t("Contribution"), color: "#795548" }, // Brown
    { label: t("Dispositions"), color: "#388e3c" }, // Green
    { label: t("Ordonnance"), color: "#1976d2" }, // Blue
    { label: t("Ordre"), color: "#8e24aa" }, // Purple
    { label: t("Régime"), color: "#ff7043" }, // Light Red
    { label: t("Circulaire"), color: "#9c27b0" }, // Violet
    { label: t("Incitation"), color: "#ffeb3b" }, // Yellow
    { label: t("Régularisation"), color: "#43a047" }, // Dark Green
    { label: t("Annulation"), color: "#d32f2f" }, // Red
    { label: t("Liste"), color: "#2196f3" }, // Blue
    { label: t("Prime"), color: "#fbc02d" }, // Amber
  ];

  const handleCloseModal = () => {
    setIsModalOpen(false);
    clear(); // Clear selected citation when modal is closed
  };

  const handleChipClick = (docType) => {
    // Toggle chip selection
    setSelectedDocTypes((prev) => {
      if (prev.some((selected) => selected.label === docType.label)) {
        return prev.filter((selected) => selected.label !== docType.label);
      }

      // Google Analytics event for filter selection
      ReactGA.event({
        category: "Filter",
        action: "User selected a filter",
        label: docType.label,
      });

      return [docType, ...prev]; // Add to the start
    });
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/search",
      title: "Search Screen",
    });
  }, []);

  const handleUnselectChip = (docType) => {
    setSelectedDocTypes((prev) =>
      prev.filter((selected) => selected.label !== docType.label)
    );

    // Google Analytics event for filter removal
    ReactGA.event({
      category: "Filter",
      action: "User removed a filter",
      label: docType.label,
    });
  };

  // Updated filteredCitations logic:
  const filteredCitations = citations?.filter((citation) => {
    // If no docType is selected, show all citations
    if (selectedDocTypes.length === 0) {
      return true;
    }

    // Check if the doc_type is in the selected filters using French translation
    const isDocTypeMatch = selectedDocTypes.some(
      (docType) =>
        docType.label.toLowerCase() ===
        t(citation.metadata.doc_type).toLowerCase()
    );

    // Check if the long_title contains the search query
    const isTitleMatch = citation.metadata.long_title
      .toLowerCase()
      .includes(searchQuery.toLowerCase()); // Case-insensitive match

    // Return citations that match either docType or longTitle
    return isDocTypeMatch || isTitleMatch;
  });

  // Pagination logic: Only paginate the filtered citations
  const paginatedCitations = filteredCitations?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalResultsCount = filteredCitations ? filteredCitations.length : 0;

  const availableDocTypes = docTypes.filter(
    (docType) =>
      !selectedDocTypes.some((selected) => selected.label === docType.label)
  );

  // Ref for the listbox
  const listboxRef = React.useRef();

  const handleCitationClick = (doc) => {
    const selectedCitation_ = {
      ...doc.metadata,
      text: doc.text,
      id: doc?.id,
    };
    setSelectedCitation(selectedCitation_);

    // Open modal if on a mobile device
    if (isMobile()) {
      showModalIfMobile();
    }

    // Google Analytics event for citation click
    ReactGA.event({
      category: "Citation",
      action: "User clicked on a citation",
      label: `Document ID: ${doc.metadata.document_id}, Section Title: ${doc.metadata.section_title}`,
    });

    setLoading(true);
  };

  const handlePageChange = (event, value) => {
    console.log("valuessXXX", value);
    // Update the current page when a new page is selected
    setCurrentPage(value);
  };

  const handleSearch = async (event) => {
    if (event.key === "Enter") {
      setLoadingSearch(true); // Set loadingSearch to true before dispatching the search
      await dispatch(searchCitations(searchQuery, user_id, mode));

      // Google Analytics event for search
      ReactGA.event({
        category: "Search",
        action: "User searched for an element",
        label: searchQuery,
        value: mode,
      });

      setOffset(0);
      setLoadingSearch(false); // Set loadingSearch to false after the search completes
    }
  };

  // Define the debounced version of handleSearchInputChange
  const debouncedHandleSearchInputChange = debounce((value) => {
    if (value && !loadingSearch) {
      // Fetch autocomplete suggestions based on input
      dispatch(fetchAutocompleteResults(value));
    }
  }, 800);

  const handleSearchInputChange = (event, value, reason) => {
    if (reason !== "createOption" && reason !== "reset") {
      // Only clear the input if the reason is "clear" or if the input is empty
      if (reason === "clear" || value === "") {
        dispatch(setSearchQuery(""));
      } else {
        dispatch(setSearchQuery(value));
        // Call the debounced function for fetching autocomplete
        debouncedHandleSearchInputChange(value);
        setOffset(0);
      }
    }
  };

  // Function to handle mode change
  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (scrollTop + clientHeight >= scrollHeight && !isFetchingMore) {
      setIsFetchingMore(true); // Set fetching indicator to true

      const newOffset = offset + 7;
      const previousScrollTop = scrollTop; // Save the current scroll position

      dispatch(fetchRecentSearches(user_id, newOffset)).then((results) => {
        if (results.length > 0) {
          setOffset(newOffset);

          // Restore the scroll position
          if (listboxRef.current) {
            listboxRef.current.scrollTop = previousScrollTop;
          }
        }
        setIsFetchingMore(false); // Set fetching indicator to false after fetching is complete
      });
    }
  };

  const handleAutocompleteSelect = (event, value) => {
    if (value) {
      // Dispatch the selected citation to Redux store
      dispatch(searchSuccess(value?.results));
    }
  };

  // useEffect(() => {
  //   dispatch(resetSearchStore());
  // }, []);

  return (
    <Box
      sx={{
        backgroundColor: colors.bg_color,
        maxWidth: open ? "calc(100vw - 279.5px)" : "93.1vw",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          borderRadius: styles.main_container_radius,
          overflow: "hidden",
        }}
      >
        <Grid container sx={{ height: "95vh" }}>
          {/* Left Section (Document List and Search) */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: "100%",
              borderRight: "1px solid #e0e0e0",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "space-between", // Space elements horizontally
                width: "100%", // Ensure the main container takes full width
                marginTop: "-16px",
              }}
            >
              {/* SEARCH BAR */}
              <Box
                sx={{
                  // backgroundColor: 'red',
                  display: "flex",
                  alignItems: "center", // Center items vertically within the Box
                  alignContent: "center",
                  justifyContent: "center",
                  width: "80%",
                }}
              >
                <Autocomplete
                  key={"search-input"}
                  sx={{
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    paddingTop: "16px",
                  }}
                  freeSolo
                  options={autocompleteResults || []} // Show autocomplete results
                  getOptionLabel={(option) => option.title || ""}
                  onInputChange={handleSearchInputChange}
                  onChange={handleAutocompleteSelect}
                  inputValue={searchQuery}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t("search_placeholder")}
                      variant="outlined"
                      fullWidth
                      sx={{ marginBottom: "16px" }}
                      onKeyDown={handleSearch}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton disabled sx={{ margin: 0, padding: 0 }}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: { fontSize: "14px" }, // Adjust font size here
                      }}
                    />
                  )}
                  ListboxComponent={CustomListboxComponent}
                  ListboxProps={{
                    onScroll: handleScroll,
                    style: { maxHeight: 200, overflow: "auto" },
                    isFetchingMore: isFetchingMore,
                    ref: listboxRef,
                  }}
                  renderOption={(props, option, index) => {
                    const { key, ...rest } = props; // Destructure key from props

                    return (
                      <ListItem key={Math.random()} {...rest}>
                        <Box sx={{ width: "90%" }}>
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "0.9rem",
                              color: "#333",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {option.title}
                          </Typography>
                        </Box>
                      </ListItem>
                    );
                  }}
                />
              </Box>

              {/* SEARCH TYPE SELECTION */}
              <Box
                display="flex"
                justifyContent="center"
                sx={{ marginLeft: 2 }}
              >
                <ToggleButtonGroup
                  value={mode}
                  exclusive
                  onChange={handleModeChange}
                  aria-label="Mode selection"
                >
                  <Tooltip title={t("search_type_regulation")} arrow>
                    <StyledToggleButton
                      sx={{ fontSize: fontSize.xsmall, mx: 1 }}
                      value="loi"
                    >
                      {t("mode_reg")}
                    </StyledToggleButton>
                  </Tooltip>
                  <Tooltip title={t("search_type_jurisprudence")} arrow>
                    <StyledToggleButton
                      sx={{ fontSize: fontSize.xsmall, mx: 1 }}
                      value="jp"
                    >
                      {t("mode_jp")}
                    </StyledToggleButton>
                  </Tooltip>
                  <Tooltip title={t("search_type_both")} arrow>
                    <StyledToggleButton
                      sx={{ fontSize: fontSize.xsmall, mx: 1 }}
                      value="loi_et_jp"
                    >
                      {t("mode_both")}
                    </StyledToggleButton>
                  </Tooltip>
                  {[
                    "saad.lachhab@juridia.ma",
                    "omar.eladlouni@juridia.ma",
                  ].includes(user_email) && (
                    <Tooltip title="Base de données ONSSA." arrow>
                      <StyledToggleButton
                        sx={{
                          fontSize: fontSize.xsmall,
                        }}
                        value="custom"
                      >
                        {"ONSSA"}
                      </StyledToggleButton>
                    </Tooltip>
                  )}
                </ToggleButtonGroup>
              </Box>
            </Box>

            {/* FILTER SECTION */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                height: "80px", // Ensure sufficient height for arrows and chips
              }}
            >
              {/* Left Arrow */}
              <IconButton
                onClick={() => {
                  const scrollContainer = document.getElementById(
                    "chips-scroll-container"
                  );
                  scrollContainer.scrollLeft -= 200; // Adjust scroll distance
                }}
                sx={{
                  // position: "absolute",
                  // left: 0,
                  zIndex: 2,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  "&:hover": { backgroundColor: "rgba(200, 200, 200, 0.8)" },
                }}
              >
                <ArrowBackIosIcon sx={{ fontSize: "12px" }} />
              </IconButton>

              {/* Chips List */}
              <Box
                id="chips-scroll-container"
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  gap: 1,
                  overflowX: "auto",
                  scrollBehavior: "smooth", // Smooth scrolling
                  flexGrow: 1, // Allow the chips container to grow
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide scrollbar in webkit browsers
                  },
                  scrollbarWidth: "none", // Hide scrollbar in Firefox
                  msOverflowStyle: "none", // Hide scrollbar in Internet Explorer/Edge
                }}
              >
                {selectedDocTypes.map((docType, index) => (
                  <Chip
                    variant="outlined"
                    key={`selected-${index}`}
                    label={
                      <>
                        {t(docType.label)}
                        <IconButton
                          size="small"
                          onClick={() => handleUnselectChip(docType)}
                          sx={{ padding: 0, marginLeft: 1 }}
                        >
                          <CloseIcon sx={{ fontSize: "16px" }} />
                        </IconButton>
                      </>
                    }
                    sx={{
                      borderColor: docType.color,
                      color: docType.color,
                      fontSize: fontSize.xsmall,
                      padding: "6px 12px",
                      cursor: "pointer",
                    }}
                  />
                ))}

                {availableDocTypes.map((docType, index) => (
                  <Chip
                    key={index}
                    label={t(docType.label)}
                    sx={{
                      borderColor: docType.color,
                      backgroundColor: selectedDocTypes.some(
                        (selected) => selected.label === docType.label
                      )
                        ? docType.color
                        : "transparent",
                      color: selectedDocTypes.some(
                        (selected) => selected.label === docType.label
                      )
                        ? "#fff"
                        : docType.color,
                      fontSize: fontSize.xsmall,
                      padding: "6px 12px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: `${docType.color}A6`, // Slightly darker shade on hover
                      },
                    }}
                    onClick={() => handleChipClick(docType)}
                  />
                ))}
              </Box>

              {/* Right Arrow */}
              <IconButton
                onClick={() => {
                  const scrollContainer = document.getElementById(
                    "chips-scroll-container"
                  );
                  scrollContainer.scrollLeft += 200; // Adjust scroll distance
                }}
                sx={{
                  // position: "absolute",
                  // right: 0,
                  zIndex: 2,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  "&:hover": { backgroundColor: "rgba(200, 200, 200, 0.8)" },
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: "12px" }} />
              </IconButton>
            </Box>

            {/* CITATIONS LIST */}
            <List sx={{ overflowY: "auto", flexGrow: 1 }}>
              {loading || loadingSearch ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress size={40} thickness={4} />
                </Box>
              ) : error ? (
                <Typography color="error">{t("loading_error")}</Typography>
              ) : filteredCitations?.length === 0 ? (
                // Display the message when no citations are present
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    color: "lightgray",
                  }}
                >
                  <Typography variant="h7">{t("no_results")}</Typography>
                </Box>
              ) : (
                paginatedCitations?.map((citation) => (
                  <ListItem
                    key={citation?.id}
                    onClick={() => handleCitationClick(citation)}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      padding: "8px 0",
                      backgroundColor:
                        selectedCitation?.id === citation?.id
                          ? colors.gray_0
                          : "transparent",
                    }}
                  >
                    <Box
                      sx={{
                        // width: "100px",
                        marginLeft: 0,
                        // backgroundColor:'red',
                        minWidth: windowWidth / 23,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Chip
                        label={t(citation.metadata.doc_type)}
                        variant="outlined"
                        size="small"
                        sx={{
                          fontSize: fontSize.xsmall,
                          color: (() => {
                            switch (citation.metadata.doc_type) {
                              case "Arrêté":
                                return "#1976d2"; // Blue
                              case "Décret":
                                return "#388e3c"; // Green
                              case "Dahir":
                                return "#d32f2f"; // Red
                              default:
                                return "#555"; // Default color
                            }
                          })(),
                          borderColor: (() => {
                            switch (citation.metadata.doc_type) {
                              case "Arrêté":
                                return "#1976d2";
                              case "Décret":
                                return "#388e3c";
                              case "Dahir":
                                return "#d32f2f";
                              default:
                                return "#555";
                            }
                          })(),
                        }}
                      />
                    </Box>

                    <Box sx={{ width: "90%", paddingLeft: windowWidth / 800 }}>
                      <Tooltip title={citation?.metadata?.long_title} arrow>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "0.9rem",
                            color: "#333",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {citation?.metadata?.long_title}
                        </Typography>
                      </Tooltip>
                      {citation?.metadata?.section_title.length > 0 && (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#666",
                            fontSize: "0.8rem",
                            marginBottom: "4px",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {"(" +
                            citation?.metadata?.section_title
                              .replace("N/A ", "")
                              .replace(":", "")
                              .trim() +
                            ") " +
                            citation.text}
                        </Typography>
                      )}
                      <Typography
                        variant="caption"
                        sx={{ color: "#999", fontSize: "0.75rem" }}
                      >
                        {(() => {
                          // Check if the date is in ISO format (yyyy-mm-dd) or ISO full date-time format
                          const isoDateRegex =
                            /^\d{4}-\d{2}-\d{2}(|T\d{2}:\d{2}:\d{2})$/;

                          if (
                            isoDateRegex.test(citation?.metadata?.creation_date)
                          ) {
                            // Convert ISO date format to a human-readable format
                            const date = new Date(
                              citation?.metadata?.creation_date
                            );
                            const formattedDate = date.toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              }
                            );
                            return formattedDate;
                          }

                          // Handle cases where the date might be in dd/mm/yyyy format
                          const dateParts =
                            citation?.metadata?.creation_date.split("/");
                          if (dateParts.length === 3) {
                            const [day, month, year] = dateParts;
                            const formattedDate = new Date(
                              `${year}-${month}-${day}`
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            });
                            return formattedDate;
                          }

                          // If no format matches, return an error or default value
                          return "Invalid date format";
                        })()}
                      </Typography>
                    </Box>
                  </ListItem>
                ))
              )}
            </List>

            {/* Pagination */}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body2">{`${totalResultsCount} ${t(
                "results_count"
              )}`}</Typography>
              <Pagination
                count={Math.ceil(totalResultsCount / 10)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </Box>
          </Grid>

          {/* Right Section (Document Viewer) */}
          {!isMobile() && (
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                padding: "16px", // Add padding for better alignment
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <HtmlViewer />
              </Box>
            </Grid>
          )}

          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "90%",
                height: "80vh",
                backgroundColor: "white",
                padding: "16px",
                borderRadius: styles.main_container_radius,
                overflow: "auto",
              }}
            >
              <HtmlViewer />
            </Box>
          </Modal>
        </Grid>
      </Paper>
    </Box>
  );
};

export default SearchScreen;
